/* eslint-disable react/jsx-props-no-multi-spaces */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable array-callback-return */
/* eslint-disable no-return-assign */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import Head from "next/head";
import dynamic from "next/dynamic";
import PropTypes from "prop-types";
import React, {
	useCallback, useEffect, useState, useMemo,
} from "react";
import { useRouter } from "next/router";
import {
	IconSearch, IconBell, IconShoppingCart,
	// IconHeart,
} from "@tabler/icons";
import axios from "axios";
import Cookies from "js-cookie";
import {
	IconMyOrder,
} from "Components/icons";
import { useDispatch, useSelector } from "react-redux";
import Env from "Consts/env";
import { ProductCardAltoshift } from "@fdn/product_card";
import { useDebounceValue } from "hooks/hooks";
import { Input } from "Components/form";
import {
	IdrConvert,
	ScrollToUp,
	HandleCreateQuote,
	// openZendeskWidget,
	// closeZendeskWidget,
	isProduction,
} from "Helpers/utils";
import { isMobile as isMobileLib } from "react-device-detect";
import BrandCard from "@fdn/brand_card";
import { setDataLayer } from "Helpers/dataLayer";
import { loginOrSignUp } from "Helpers/is498";
import Vars from "../consts/vars";
import { loadCartBadgeCount } from "@/Store/cart_badge_count_store/lib/actions";
import { loadMergeCart } from "@/Store/merge_cart_store/lib/actions";

const baseAppName = "FD Studio";
let appName = "";
const metaDefault = {
	title: "Temukan Diskon Skincare dan Kosmetik Terlengkap",
	description: "Temukan Produk terkini dan terlengkap seputar dunia kecantikan mulai dari skin care, makeup, review produk dan brand kecantikan lokal maupun internasional.",
	image: "https://s3.ap-southeast-1.amazonaws.com/assets.femaledaily.com/error-image/image-error.png",
};

const LoadingSection = dynamic(
	() => import("./loading-section"),
	{ ssr: false },
);

const NavbarHeader = dynamic(
	() => import("@fdn/navbar_header"),
	{ ssr: false, loading: () => <LoadingSection type="navbar-header" isMobile={isMobileLib} /> },
);

const Popup = dynamic(
	() => import("Components/pdp-popup"),
	{ ssr: false },
);

const Footer = dynamic(
	() => import("./footer"),
	{ ssr: false },
);

const Sidebar = dynamic(
	() => import("Components/sidebar"),
	{ ssr: false },
);

const NavbarSecondary = dynamic(
	() => import("Components/navbar-secondary"),
	{ ssr: false },
);

const CategoryDesktop = dynamic(
	() => import("Components/category-desktop"),
	{ ssr: false },
);

// const ZendeskLauncher = dynamic(
// 	() => import("Components/zendesk-launcher"),
// 	{ ssr: false },
// );

export default function Layout({
	children,
	titleNavbar,
	typeNavbarSecondary,
	ismobile,
	self,
	isFooter,
	categoryDesktop,
	getQuoteIdErrMessage,
	pageTitle,
	metaDescription,
	metaKeywords,
	metaTitle,
	metaImage,
	metaUrlPath,
	device,
	hideMetaDescription,
}) {

	let newCategoryDesktop = [];
	newCategoryDesktop = self?.data?.id
		? categoryDesktop?.filter((item) => !["for-you"].includes(item.slug) && item)
		: categoryDesktop;

	const isMetaTitleWithBaseName = metaTitle?.includes("|");
	if (typeof metaTitle !== "undefined") {

		const cleanMetaTitle = metaTitle?.split("|")?.[0];
		appName = `${pageTitle || cleanMetaTitle} | ${baseAppName}`;

	}

	const isMobile = ismobile || false;

	useCallback(() => {

		ScrollToUp();

	}, []);

	const { pathname } = useRouter();

	const dispatch = useDispatch();
	const getCartBadgeCount = useSelector((state) => state.storeCartBadgeCount.data);

	const [searchText, setSearchText] = useState("");
	const [isShowSearch, setShowSearch] = useState(false);
	const [isShowSearchInput, setShowSearchInput] = useState(false);
	const [searchLoading, setSearchLoading] = useState(false);
	const [productsSearchResult, setProductsSearchResult] = useState([]);
	const [brandsSearchResult, setBrandsSearchResult] = useState([]);
	const [isShowSidebar, setShowSidebar] = useState(false);
	const [isNavbarSecondary, setNavbarSecondary] = useState(false);
	const [isNavbarHeader, setNavbarHeader] = useState(true);
	const [popupShow, setPopupShow] = useState({
		show: false,
		message: "",
		color: "#323942",
	});
	const getMergeCart = useSelector((state) => (state.storeMergeCart?.data));
	// const [isZendeskLoad, setIsZendeskLoad] = useState(false);

	const [IsMyOrderPopup, SetIsMyOrderPopup] = useState(false);

	const updateSearchText = async (e) => {

		const fetchSearchProductsAndBrands = async () => {

			try {

				const listProducts = await fetch(
					`/api/fds-product?limit=4&query=${encodeURIComponent(e)}&placement=3`,
					{
						headers: {
							...Env.HEADERS,
							key: process.env.NEXT_PUBLIC_KEY_API_CLIENT,
						},
					},
				);

				const resProducts = await listProducts.json();

				const listBrands = await fetch(
					`/api/fds-brands?limit=4&query=${encodeURIComponent(e)}&placement=3`,
					{
						headers: {
							...Env.HEADERS,
							key: process.env.NEXT_PUBLIC_KEY_API_CLIENT,
						},
					},
				);

				const resBrands = await listBrands.json();

				return { resProducts, resBrands };

			} catch (err) {

				setPopupShow({
					show: true,
					message: "Something went wrong. Please try again later.",
					color: "#323942",
				});
				return `Error: ${err}`;

			}

		};

		if (e.length >= 3) {

			setShowSearch(true);

			setSearchLoading(true);

			// eslint-disable-next-line no-unused-expressions
			isProduction ? setDataLayer({
				event: "typing_keyword_on_search",
				username: self.data?.username || "guest",
				is_login: !!self.data?.username,
				device: isMobile ? "mobile" : "desktop",
				keyword: searchText,
				referral: `fromglobalheaderfdstudio${pathname.split("/")[1].replace(/[-]/g, "")}`,
			}, "GTM") : null;

			fetchSearchProductsAndBrands().then(({ resProducts, resBrands }) => {

				if ((resProducts?.meta?.code >= 200) && (resProducts && resProducts.data)) {

					setProductsSearchResult(resProducts.data.products);

				}

				if ((resBrands?.meta?.code >= 200) && (resBrands && resBrands.data)) {

					setBrandsSearchResult(resBrands.data);

				}

			}).finally(() => {

				setSearchLoading(false);

			});

		} else {

			setShowSearch(false);

		}

	};

	const debouncedSearchText = useDebounceValue(
		searchText,
		500,
	);

	const submitSearch = (e) => {

		e.preventDefault();
		window.location.href = `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/search?query=${encodeURIComponent(searchText)}`;

	};

	const onClickCustomIcon = (val) => {

		if (val?.target?.className?.baseVal?.includes("cart")) {

			window.location.href = `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/cart`;

		} else if (val?.target?.className?.baseVal?.includes("bell")) {

			window.location.href = `${process.env.NEXT_PUBLIC_REVIEWS_DOMAIN}/notification?tab=reviews`;

		}

	};

	const showSearch = () => {

		setShowSearchInput(true);

	};

	const HandleClickBack = () => {

		window.history.go(-1); return false;

	};

	const HandleClickSearch = () => {

		window.location.href = `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/search?query=${encodeURIComponent(searchText)}`;

	};

	const HandleClickCart = () => {

		window.location.href = "/cart";

	};

	const HandleKeyPress = (e) => {

		if (e && (e.charCode === 13 || e.code === "Enter")) {

			window.location.href = `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/search?query=${encodeURIComponent(searchText)}`;
			setSearchText("");

		}

	};

	useEffect(() => {

		const onScroll = () => {

			if (window) {

				const windowHeight = window.scrollY;
				const regexList = [/^\/$/,
					/story/,
					/faq/,
					/privacy-policy/,
					/terms-and-conditions/,
					/detail/,
					/cart/];
				const isMatch = regexList.some((rx) => rx.test(pathname.toString()));

				if (
					isMatch
					&& isMobile
				) {

					if (windowHeight > 62) {

						setNavbarSecondary(true);
						document.getElementsByClassName("navbar-header")[0]?.style.setProperty("display", "none");

					} else {

						setNavbarSecondary(false);
						document.getElementsByClassName("navbar-header")[0]?.style.setProperty("display", "block");

					}

				}

			}

		};

		window.addEventListener("scroll", onScroll);
		return () => window.removeEventListener("scroll", onScroll);

	}, [isMobile]);

	useEffect(() => {

		const onScroll = () => {

			if (window) {

				const regexList = [/^\/$/,
					/story/,
					/faq/,
					/privacy-policy/,
					/terms-and-conditions/,
					/detail/,
					/cart/];
				const isMatch = regexList.some((rx) => rx.test(pathname.toString()));
				if (device && (device !== 1 || device !== 2)) {

					setNavbarSecondary(false);
					setNavbarHeader(false);

				} else if (!isMatch && isMobile) {

					setNavbarSecondary(true);
					setNavbarHeader(false);

				}

			}

		};
		onScroll();

	}, []);

	useEffect(() => {

		if (getQuoteIdErrMessage) {

			setPopupShow({
				show: true,
				message: "Mohon untuk direfresh kembali",
				color: "#323942",
			});

		}

	}, [getQuoteIdErrMessage]);

	useEffect(() => {

		if (searchText.length >= 3) {

			updateSearchText(debouncedSearchText);

		} else {

			setShowSearch(false);

		}

	}, [debouncedSearchText]);

	useEffect(() => {

		const token = Cookies.get("token") || null;
		const quoteId = Cookies.get("quoteId") || null;
		const userId = Cookies.get("user_id") || null;

		const createQuote = async () => {

			const res = await HandleCreateQuote({}, dispatch);
			return res;

		};

		if (token && !userId && !quoteId) {

			console.log("QUOTE AND MERGE");
			createQuote().then((res) => {

				if (res.data.meta.code >= 200 && res.data.meta.code < 300 && res.data) {

					dispatch(loadMergeCart());

				}

			});

		} else if (token && quoteId && !userId) {

			console.log("MERGE");
			dispatch(loadMergeCart());

		} else if (!token && !quoteId && !userId) {

			createQuote();

		}

	}, []);

	useEffect(() => {

		const isUserId = getMergeCart?.data?.user_id || null;

		if (isUserId) {

			const domain = (new URL(`https://${process.env.NEXT_PUBLIC_HOST_ROOT_DOMAIN}`)).hostname.replace("www.", "");
			Cookies.remove("quoteId", { path: "", domain });
			Cookies.set("user_id", getMergeCart?.data?.user_id, { path: "", domain });

		}

		const mergeCartErrorMessage = getMergeCart?.meta?.msg;

		if (getMergeCart?.meta?.code === 400 && mergeCartErrorMessage) {

			setPopupShow({
				show: true,
				message: `${mergeCartErrorMessage}`,
				color: "#FF4D4F !important",
				closable: true,
				redirect: false,
			});

		}

	}, [getMergeCart]);

	axios.defaults.headers = {
		...Env.HEADERS,
	};

	const onLogout = () => {

		const domain = (new URL(process.env.NEXT_PUBLIC_ROOT_HOME)).hostname.replace("www.", "");
		Cookies.remove("token", { path: "", domain });
		Cookies.remove("quoteId", { path: "", domain });
		Cookies.remove("tokenMagento", { path: "", domain });
		Cookies.remove("user_id", { path: "", domain });
		window.location.href = `${process.env.NEXT_PUBLIC_SSO_DOMAIN}/logout`;

	};

	/* START LOAD CART TOTAL */
	useEffect(() => {

		dispatch(loadCartBadgeCount());

	}, []);
	/* END LOAD CART TOTAL */
	const SearchContent = 	isShowSearch && (
		<>
			<div className="search-container">
				<div className="search-section">
					<span className="title product">
						Product
					</span>
					<div className="search-section-items product-search-section-items">
						{!searchLoading
						&& productsSearchResult?.length > 0
						&& productsSearchResult.map((item, index) => (
							<ProductCardAltoshift
								is_mobile={isMobile}
								type="fds"
								font_family="Creato Display, sans-serif"
								key={item.sku ?? String(index)}
								product={{
									id: item.id,
									price: IdrConvert(item.price),
									item_id: item.id,
									search_id: item.id,
									name: item.name,
									slug: item.slug,
									image: item.image_url,
									brand: {
										name: item.brands.brand_name,
										slug: item.brands.brand_slug,
									},
									category: {
										name: "dummy",
										slug: "dummy",
										parent: {
											name: "dummy",
											slug: "dummy",
										},
									},
									rating: {
										average: item.avg_rating ?? 0,
										user: 0,
										total: item.review_count ?? 0,
									},
								}}
								product_url={`${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/detail/${item?.slug}?type=${item?.type_id}`}
								brand_url={`${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/brands/${item?.brands?.brand_name?.toLowerCase().replace(/ /gi, "-")}`}
							/>
						))}
						{searchLoading && (
							<>
								<img
									src="https://s3-ap-southeast-1.amazonaws.com/assets.femaledaily.com/global-header/loader_blink.gif"
									className="loader-gif"
									alt="loader-blink"
								/>
								<style>
									{`

										div.search-container div.search-section div.search-section-items {
											// display: flex !important;
											justify-content: center !important;
											align-items: center !important;
											padding-bottom: 12px !important;
										}

										img.loader-gif {
											width: 100px;
										}

									`}
								</style>
							</>
						)}
						{!searchLoading && productsSearchResult && productsSearchResult.length === 0 && (
							<>
								<span className="no-result-search">
									Sorry, we couldn&apos;t find any matching products for &quot;
									{searchText}
									&quot;.
								</span>
								<style>
									{`

										div.search-container div.search-section .product-search-section-items {
											display: flex !important;
											justify-content: center !important;
											align-items: center !important;
											padding-bottom: 12px !important;
										}

										span.no-result-search {
											font-size: ${isMobile ? "12px" : "14px"};
										}

									`}
								</style>
							</>
						)}
					</div>
				</div>
				<div className="search-section">
					<span className="title">
						Brand
					</span>
					<div className={`search-section-items brand-search-section-items${isMobile ? "-mobile" : ""}`}>
						{!searchLoading
						&& brandsSearchResult?.length > 0
						&& brandsSearchResult.slice(0, 4).map((item, index) => (
							<BrandCard
								is_mobile={isMobile}
								key={item.brand_id ?? String(index)}
								brand_url={`${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/brands/${item.brand_slug.toLowerCase().replace(/ /gi, "-")}`}
								brand_image={item.image}
								brand_name={item.brand_name}
								brand_slug={item.brand_slug}
								brand_total_products={item.product_count}
								type="fds"
							/>
						))}
						{searchLoading && (
							<>
								<img
									src="https://s3-ap-southeast-1.amazonaws.com/assets.femaledaily.com/global-header/loader_blink.gif"
									className="loader-gif"
									alt="loader-blink"
								/>
								<style>
									{`

										div.search-container div.search-section div.search-section-items {
											display: flex !important;
											justify-content: center !important;
											align-items: center !important;
											padding-bottom: 12px !important;
										}

										img.loader-gif {
											width: 100px;
										}

									`}
								</style>
							</>
						)}
						{!searchLoading && brandsSearchResult && brandsSearchResult.length === 0 && (
							<>
								<span className="no-result-search">
									Sorry, we couldn&apos;t find any matching brands for &quot;
									{ searchText }
									&quot;.
								</span>
								<style>
									{`

										div.search-container div.search-section .brand-search-section-items,
										div.search-container div.search-section .brand-search-section-items-mobile {
											display: flex !important;
											justify-content: center !important;
											align-items: center !important;
											padding-bottom: 12px !important;
										}

										span.no-result-search {
											font-size: ${isMobile ? "12px" : "14px"};
										}

									`}
								</style>
							</>
						)}
					</div>
				</div>
			</div>
			<style>
				{`
					div.search-container div.search-section {
						width: 100%;
					}

					div.search-container div.search-section span.title {
						background-color: #FFF;
						color: #454F5B;
						padding: 12px 16px;
						display: block;
						font-size: 14px;
						letter-spacing: 0.04em;
					}

					div.search-container div.search-section .product-search-section-items {
						display: grid;
						grid-gap: 15px;
						grid-template-columns: repeat(2, 1fr);
					}
					div.search-container div.search-section .brand-search-section-items {
						display: grid;
						grid-gap: 15px;
						overflow-y: hidden;
					}
					div.search-container div.search-section .brand-search-section-items-mobile {
						display: flex;
						grid-gap: 8px;
						grid-template-columns: repeat(3, 1fr);
						overflow-y: hidden;
					}
					div.search-container div.search-section div.search-section-items {
						padding: ${!isMobile ? "21px 16px;" : "0 16px 21px 16px;"}
					}

					div.search-container div.search-section div.search-section-items div.brand-card{
						flex-wrap: nowrap;
					}
				`}
			</style>
		</>
	);

	const isZendeskShow = useMemo(() => {

		const regexList = [/^\/$/,
			/(\/brands\/[^/?]+)($|\/|\?)/,
			/detail/,
			/category/,
			/search/,
		];
		return regexList.some((rx) => rx.test(pathname.toString()));

	}, [pathname]);

	const mainContentPadding = () => {

		let padding;
		if (!isMobile) {

			padding = "56px 0";

		} else if (!isNavbarHeader) {

			padding = "63px 0 0 0";

		} else {

			padding = "0";

		}

		return padding;

	};

	useEffect(!isZendeskShow
		? () => {}
		: () => {

			const script = document.createElement("script");
			script.src = "https://static.zdassets.com/ekr/snippet.js?key=944e5ef8-049f-4b62-aa8d-fc15e679561f";
			script.id = "ze-snippet";
			script.setAttribute("type", "text/javascript");
			script.async = true;
			document.head.appendChild(script);
			script.onload = () => {

				const scriptTag = document.createElement("script");
				scriptTag.type = "text/javascript";
				scriptTag.text = `
					zE("webWidget", "hide")
					zE('webWidget:on', 'close', function() {
						zE('webWidget', 'hide');
						document.querySelector('.zendeskChatWidget').style.opacity = 1;
					})
					`;
				document.body.appendChild(scriptTag);
				// setIsZendeskLoad(true);

			};

			return () => {

				// setIsZendeskLoad(false);
				document.head.removeChild(script);

			};

		}, []);

	const remapUrl = (url) => {

		const encoded = encodeURIComponent(url);
		const newUrl = `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/_next/image?url=${encoded}&w=1200&q=80`;

		return newUrl;

	};

	return (
		<>
			<Head>
				<title>{appName}</title>
				<link rel="icon" href="https://s3.ap-southeast-1.amazonaws.com/assets.femaledaily.com/beauty_studio/favicon.ico" />
				<meta charSet="utf-8" />
				<meta httpEquiv="X-UA-Compatible" content="IE=edge" />
				<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=5" />
				<link rel="apple-touch-icon-precomposed" sizes="144x144" href={`${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/images/fdn_logo_144.png`} />
				<link rel="apple-touch-icon-precomposed" sizes="114x114" href={`${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/images/fdn_logo_144.png`} />
				<link rel="apple-touch-icon-precomposed" sizes="72x72" href={`${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/images/fdn_logo_72.png`} />
				<link rel="apple-touch-icon-precomposed" sizes="57x57" href={`${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/images/fdn_logo_57.png`} />

				{/* META AUTHOR */}
				<meta name="author" content="Female Daily Network" />
				<meta
					name="title"
					content={isMetaTitleWithBaseName ? metaTitle : `${metaTitle || metaDefault.title} | ${baseAppName}`}
				/>

				<meta
					name="keywords"
					content={metaKeywords}
				/>
				<meta property="og:locale" content="en_US" />
				<meta property="og:locale" content="id_ID" />
				<meta property="og:type" content="website" />
				<meta name="twitter:card" content="summary_large_image" />

				<meta property="og:title" content={isMetaTitleWithBaseName ? metaTitle : `${metaTitle || metaDefault.title} | ${baseAppName}`} />
				<meta name="twitter:title" content={isMetaTitleWithBaseName ? metaTitle : `${metaTitle || metaDefault.title} | ${baseAppName}`} />

				<meta
					name="description"
					content={hideMetaDescription ? "" : metaDescription || metaDefault.description}
				/>
				<meta property="og:description" content={hideMetaDescription ? "" : metaDescription || metaDefault.description} />
				<meta name="twitter:description" content={hideMetaDescription ? "" : metaDescription || metaDefault.description} />

				<meta property="og:keywords" content={metaKeywords} />
				<meta property="og:url" content={`${process.env.NEXT_PUBLIC_ROOT_DOMAIN}${metaUrlPath || ""}`} />
				<meta property="og:site_name" content="Female Daily Studio" />

				<meta property="og:image" content={metaImage ? remapUrl(metaImage) : metaDefault.image} />
				<meta property="og:image:url" content={metaImage ? remapUrl(metaImage) : metaDefault.image} />
				<meta property="og:image:width" content="1200" />
				<meta property="og:image:height" content="630" />

				<meta property="fb:app_id" content={process.env.NEXT_PUBLIC_FB_APP_ID} />

				<meta name="twitter:image" content={metaImage ? remapUrl(metaImage) : metaDefault.image} />
				<meta name="twitter:image:src" content={metaImage ? remapUrl(metaImage) : metaDefault.image} />

				<link rel="stylesheet" href="https://s3.ap-southeast-1.amazonaws.com/assets.femaledaily.com/fdn-studio-new/static/styles/font_google.css" />
				<link rel="stylesheet" type="text/css" href="https://s3.ap-southeast-1.amazonaws.com/assets.femaledaily.com/fdn-studio-new/static/styles/icomoon/styles.css" />
				<link rel="stylesheet" type="text/css" href="/styles/style.css" />
				<link rel="stylesheet" type="text/css" href="/styles/typography.css" />
				<link rel="stylesheet" href="https://s3.ap-southeast-1.amazonaws.com/assets.femaledaily.com/web-assets/fonts/fdnfonts.css" />
				<link rel="stylesheet" type="text/css" href="https://s3.ap-southeast-1.amazonaws.com/assets.femaledaily.com/font/fontastic/styles.css" />
				<link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />
				<link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />
				<link rel="stylesheet" href="https://unpkg.com/@tabler/icons@latest/iconfont/tabler-icons.min.css" />
				<script src="https://unpkg.com/@tabler/icons@latest/icons-react/dist/index.umd.min.js" />

			</Head>

			{/* {isZendeskShow && isZendeskLoad && (
				<ZendeskLauncher
					isMobile={isMobile}
					onShow={openZendeskWidget}
					onClose={closeZendeskWidget}
				/>
			)} */}

			{			isNavbarHeader
			&& (
				<NavbarHeader
					isNewIcon
					logo_img="https://s3.ap-southeast-1.amazonaws.com/assets.femaledaily.com/beauty_studio/logo-fd-studio.png"
					is_mobile={isMobile}
					type="monochrome"
					self={self && self.data}
					is_fetch_complete={self?.status === Vars.FETCH_RESULT
						|| self?.status === Vars.FETCH_ERROR}
					nav_position="fixed"
					menu={Vars.top_menu}
					main_link={process.env.NEXT_PUBLIC_ROOT_DOMAIN}
					home_link="/"
					reviews_link={process.env.NEXT_PUBLIC_REVIEWS_DOMAIN}
					talk_link={process.env.NEXT_PUBLIC_TALK_DOMAIN}
					sso_link={process.env.NEXT_PUBLIC_SSO_DOMAIN}
					is_user_verified={self && !self.data?.is_verified}
					profile_link={self && `${process.env.NEXT_PUBLIC_SSO_DOMAIN}/user/${self.data?.username}`}
					sidebar_config={Vars.config_sidebar}
					logo_id="id_logo_editorial"
					search_input_id="id_search_input_field_editorial"
					button_login_id="id_loginsignup_editorial"
					button_cart_id="id_carteditorial"
					button_notif_id="id_notifeditorial"
					submit_search={(e) => submitSearch(e)}
					search_text={searchText}
					update_search_text={(e) => setSearchText(e.target.value)}
					toggle_sidebar={() => setShowSidebar(true)}
					close_search_suggest={() => {

						setSearchText("");
						setShowSearch(false);

					}}
					is_show_search_suggest={isShowSearch}
					is_show_search={isShowSearchInput}
					show_search={showSearch}
					hide_search={() => {

						setShowSearch(false);
						setShowSearchInput(false);

					}}
					customIcons={
						[
							<span key="1" className="cart" data-cart={Number(getCartBadgeCount) || 0} style={{ cursor: "pointer" }}>
								<IconShoppingCart stroke="1" />
								<style>
									{`
								.cart::after {
									visibility: ${(Number(getCartBadgeCount) || 0) ? "visible" : "hidden"};
									content: attr(data-cart);
									font-size: 10px;
									background: #DB264D;
									color: #fff;
									vertical-align: top;
									position: absolute;
									top: 15px;
									margin-left: -3px;
									border-radius: 50%;
									width: 16px;
									height: 16px;
									text-align: center;
								}
								`}
								</style>
							</span>,
							<span key="2" className="notification" style={{ cursor: "pointer", marginLeft: "10px" }}><IconBell stroke="1" /></span>,
						]
					}
					handleClickCustomIcons={(val) => onClickCustomIcon(val)}
					onClickLoginSignup={() => {

						loginOrSignUp();

					}}
					loggedInMenu={
						(
							<>
								<a
									id="id_myorderdropdown"
									href={`${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/my-order`}
									className="option-menu"
									onMouseEnter={() => SetIsMyOrderPopup(!IsMyOrderPopup)}
									onMouseLeave={() => SetIsMyOrderPopup(!IsMyOrderPopup)}
								>
									<IconMyOrder active={IsMyOrderPopup} padding="0 8px 0 0" />
									<span>My Order</span>
								</a>
								<a
									id="id_profiledropdown"
									href={self && `${process.env.NEXT_PUBLIC_SSO_DOMAIN}/user/${self.data?.username}`}
									className="option-menu"
								>
									<i className="fdicon-bs-profile" />
									<span>Profile</span>
								</a>
								<a
									id="id_settingdropdown"
									href={self && `${process.env.NEXT_PUBLIC_SSO_DOMAIN}/my/account`}
									className="option-menu"
								>
									<i className="fdicon-bs-setting" />
									<span>Settings</span>
								</a>
								<div
									id="id_logoutdropdown"
									onClick={() => onLogout()}
									className="option-menu"
								>
									<i className="fdicon-bs-logout" />
									<span>Logout</span>
								</div>
							</>
						)
					}
					searchContent={
						SearchContent
					}
				>
					{
						isMobile && (
							<div className="wrapper-search">
								<Input
									value={searchText}
									placeholder="Search Brand or Product"
									onChange={(event) => setSearchText(event)}
									onKeyPress={(e) => HandleKeyPress(e)}
								/>
								<span className="ic-search" onClick={(e) => submitSearch(e)}><IconSearch stroke="1" size="16px" /></span>
							</div>
						)
					}
					{
						!isMobile && newCategoryDesktop && newCategoryDesktop.length > 0 && (
							<CategoryDesktop
								data={newCategoryDesktop}
								destinationUrl={`${process.env.NEXT_PUBLIC_ROOT_DOMAIN}`}
								prefixUrl="/category"
							/>
						)
					}
					<style>
						{`
						.top-menu {
							background: #FFF;
							box-shadow: inset 0px -1px 0px #DFE3E8;
							font-style: normal;
						}
						.top-menu a {
							font-family: 'Creato Display', sans-serif;
							color: #454F5B;
							letter-spacing: 0.42px;
						}
						.top-menu .title a {
							font-style: normal;
							font-weight: 400;
							font-family: 'Creato Display', sans-serif;
							font-size: 14px;
							font-style: normal;
							letter-spacing: 0.42px;
						}
						.profile-popup .profile-allo {
							display: none          
						}

						.profile-popup .option-menu i {
							margin: 0;
							padding: 0px 5px 0px 0px;
						}
						.profile-popup .profile-detail .profile-data, .profile-popup .profile-detail .profile-data .email, .profile-popup .profile-detail .profile-data .fullname, .button-profile-wrapper .name-wrapper p, .profile-popup .option-menu, .btn-login {
							font-family: 'Creato Display', sans-serif;
						}
					`}
					</style>
				</NavbarHeader>
			)}
			{
				// do not show navbar if opened from mobile app webview
				isMobile && (device !== "1" && device !== "2") && (
					<>
						<NavbarSecondary
							show={isNavbarSecondary}
							isMobile
							title={titleNavbar}
							type={typeNavbarSecondary}
							onClickSidebar={() => setShowSidebar(true)}
							onClickSearch={() => HandleClickSearch()}
							onClickCart={() => HandleClickCart()}
							onClickBack={() => HandleClickBack()}
							cartBadgeCount={getCartBadgeCount || 0}
							is_show_search_suggest={isShowSearch}
							searchContent={SearchContent}
							onChange={(e) => setSearchText(e.target.value)}
							search_text={searchText}
							onClick={() => setShowSearch(true)}
							close_search_suggest={() => setShowSearch(false)}
						/>
						<Sidebar
							show={isShowSidebar}
							user={self.data}
							sidebarCustom={newCategoryDesktop}
							onHide={() => setShowSidebar(false)}
						/>
					</>
				)
			}

			<div className="main-content fds-style">
				{children}
			</div>

			<style>
				{`
				.main-content {
					padding: ${mainContentPadding()}
				}
					@media screen and (max-width: 767px) {
						.main-content {
							max-width: 100vw;
						}
					}
				`}
			</style>

			{
				// do not show footer if opened from mobile app webview
				isFooter && (device !== "1" && device !== "2") && <Footer isMobile={isMobile} />
			}
			<Popup
				closable={popupShow?.closable ?? false}
				isMobile={isMobile}
				color={popupShow?.color}
				show={popupShow?.show}
				onHide={() => setPopupShow(false)}
				text={popupShow?.message}
				withOk={false}
			/>

			<style>
				{`
						${!isMobile ? `.main-logo-wrapper a * {
							max-height: 45px;
							height: 45px;
							width: 135px;
						}
						
						button.search-button img.ic-object {
							height: 19px;
						}
						` : ""}

						${isMobile && !isNavbarSecondary ? ".search-suggestion-wrapper { top:105px; }" : ""}

						img {
							max-width: 100%;
							max-height: 100%;
							width: auto;
							height: auto;
						}

						.navbar-header {
							width: 100vw;
							box-sizing: border-box;
							background-color: #FFF;
							position: absolute;
							top: 0;
						}

						.navbar-inner-content-wrapper {
							max-width: 1200px;
						}

						.mega-menu-wrapper {
							max-width: 1200px;
							margin: 0 auto;
							display: flex;
							justify-content: center;
						}

						.search-input-field input {
							max-width: calc(100% - 32px);
						}

						.sub-menu-title a {
							text-transform: uppercase;
						}

						.menu-popup {
							max-width: calc(100% - 48px);
						}

						img.sub-menu-item {
							max-width: 100%;
						}

						${/* isHidetopMenu && */ !isMobile
			? ".mega-menu .menus-wrapper a {font-size: 14px; margin-top: 10px; margin-bottom: 10px;}"
			: ""
		}				
						
						.navbar-header .btn-close {
							margin-left: 0;
						}

						.navbar-header .mega-menu {
							padding-bottom: 0;
						}

						.mega-menu-wrapper {
							width: 100%;
							max-width: 100%;
							margin-top: 12px;
							display: unset;
							justify-content: unset;
						}

						.navbar-header .search-popup-content {
							max-width: 100%;
						}

						.menu-popup {
							max-width: unset;
						}

						.menu-popup-title {
							justify-content: space-between;
						}

						.wrapper-search {
							position: relative;
						}
						.wrapper-search input {
							width: 100%;
							height: 36px;
							box-sizing: border-box;
							font-weight: 400;
							font-size: 14px;
							line-height: 16px;
							border: 1px solid #DFE3E8;
							border-radius: 2px;
							padding: 0 30px 0 10px;
							outline: none;
							margin: 5px 0 0 0;
						}
						.wrapper-search input:focus {
							border: 1px solid #1B8884;
						}
						.wrapper-search input::placeholder {
							color: #919EAB;
						}
						.wrapper-search .ic-search {
							position: absolute;
							right: 8px;
							top: 15px;
							cursor: pointer;
						}

						.button-profile-wrapper .profile-image, .profile-popup .profile-detail .profile-picture img {
							height: 67px;
							width: 67px;
						}

						${isMobile
			? `iframe#launcher {
									bottom: 70px !important;
									right: 12px !important;
								}`
			: ""
		}

					iframe[title="Close message"],
					iframe[title="Message from company"] {
						display: none !important;
						pointer-events: none !important;
					}
					`}
			</style>
			<style jsx global>
				{`
						.root-container {
							display: flex;
							align-items: center;
							justify-content: center;
							box-sizing: border-box;
						}
						.row-center {
							width: 480px;
							position: relative;
						}
	
						@media screen and (max-width: 425px) {
							.row-center {
								width: 100%;
							}
						}

						a {
							text-decoration: none;
						}

						body {
							margin: 0px;
							max-width: 100%;
							overflow-x: hidden;
						}
					`}
			</style>
		</>
	);

}

Layout.propTypes = {
	children: PropTypes.node.isRequired,
	titleNavbar: PropTypes.string,
	typeNavbarSecondary: PropTypes.string,
	ismobile: PropTypes.bool,
	self: PropTypes.objectOf(PropTypes.any).isRequired,
	categoryDesktop: PropTypes.any,
	isFooter: PropTypes.bool,
	getQuoteIdErrMessage: PropTypes.any,
	pageTitle: PropTypes.string,
	metaDescription: PropTypes.string,
	metaKeywords: PropTypes.string,
	metaTitle: PropTypes.string,
	metaImage: PropTypes.string,
	metaUrlPath: PropTypes.string,
	device: PropTypes.string,
	hideMetaDescription: PropTypes.bool,
};

Layout.defaultProps = {
	ismobile: false,
	isFooter: true,
	titleNavbar: "",
	getQuoteIdErrMessage: null,
	pageTitle: "",
	metaDescription: null,
	metaKeywords: null,
	metaTitle: null,
	metaImage: "",
	metaUrlPath: "",
	hideMetaDescription: false,
};
